<template>
  <div>
    <van-nav-bar title="返回" left-arrow @click-left="gohome()" />
    <div class="pageError">
      <img src="../assets/image/401.jpg" alt="" class="img" />
      <p>
        对不起，您没有访问权限，请不要进行非法操作！
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "page401",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gohome() {
      this.$router.push({
        path: "/myhome",
      });
    },
  },
};
</script>
<style scoped lang="less">
.img {
  width: 300px;
  object-fit: cover;
}
</style>
